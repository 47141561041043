function privacyCheck() {
    // SECTION: cookies
    function setCookie(cookieName: string, value: string, expirationNumberOfDays: number) {
        const d = new Date();
        d.setTime(d.getTime() + (expirationNumberOfDays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        // TODO encode the value?
        document.cookie = cookieName + "=" + value + ";" + expires + ";path=/;Secure;SameSite=Strict";
    }

    function getCookie(cookieName: string) {
        let name = cookieName + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    // SECTION: render HTML
    function renderHtml(content: string) {
        const container = document.createElement("template");
        container.innerHTML = content;
        const children = container.content.children;
        if (children.length === 1) {
            return children.item(0)!!;
        }

        const wrapper = document.createElement("span");
        wrapper.innerHTML = content;
        return wrapper;
    }

    const sitePrivacySettingsCookie = 'site_privacy_settings';

    // SECTION: gtag
    // ref https://developers.google.com/tag-platform/gtagjs/reference
    function initDefaultTracking() {
        gtag('consent', 'default', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'denied'
        });
    }

    function renderDialog() {
        return renderHtml(`
                    <div class="ps-privacy">
                        <form class="ps-privacy__dialog">
                            <section class="j-brief-disclosure">
                                <h1>promptservice.io uses cookies</h1>
                                <p>This site use cookies and other tracking technologies to improve your browsing 
                                    experience on our website, to show you personalized content and targeted ads, 
                                    to analyze our website traffic, and to understand where our visitors are coming from.
                                </p>
                                <p>
                                    <button class="j-save-accept ps-privacy__button">Accept all</button>
                                    <button class="j-save-decline ps-privacy__button">Decline all</button>
                                    <button class="j-change-privacy-settings ps-privacy__button">Change my preferences</button>
                                </p>
                            </section>
                            <section class="j-detailed-disclosure ps-detailed-disclosure ps-privacy-hidden">
                                <h1>Customize Privacy Settings</h1>
                                <section class="ps-detailed-disclosure__section">
                                    <h1 class="ps-detailed-disclosure__section__head">Strictly necessary cookies</h1>
                                    <p>
                                        These cookies are essential to provide you with services available through 
                                        our website and to enable you to use certain features of our website.
                                    </p>
                                    <p>Without these cookies, we cannot provide you certain services on our website.</p>
    
                                    <p>
                                        <input type="checkbox" id="privacy-required-consent" name="necessary" checked disabled="disabled">
                                        <label for="privacy-tracking-consent" id="tracking_label" >Allow</label>
                                    </p>
                                </section>
                                <section class="ps-detailed-disclosure__section">
                                    <h1 class="ps-detailed-disclosure__section__head">Tracking cookies</h1>
                                    
                                    <p>
                                        These cookies are used to collect information to analyze the traffic to our 
                                        website and how visitors are using our website.
                                    </p>
                                    
                                    <p>
                                        For example, these cookies may track things such as how long you spend on the 
                                        website or the pages you visit which helps us to understand how we can improve 
                                        our website for you.
                                    </p>
                                    
                                    <p>
                                        The information collected through these tracking and performance cookies do 
                                        not identify any individual visitor.
                                    </p>
                                    
                                    <p>
                                        <input type="checkbox" id="privacy-tracking-consent" name="tracking">
                                        <label for="privacy-tracking-consent" id="tracking_label">Allow</label>
                                    </p>
                                </section>
                                <section class="ps-detailed-disclosure__section">
                                    <h1 class="ps-detailed-disclosure__section__head">Targeting and advertising cookies</h1>
    
                                    <p>
                                        These cookies are used to show advertising that is likely to be of interest 
                                        to you based on your browsing habits.
                                    </p>
                                    
                                    <p>
                                        These cookies, as served by our content and/or advertising providers, may 
                                        combine information they collected from our website with other information 
                                        they have independently collected relating to your web browser's activities 
                                        across their network of websites.
                                    </p>
                                    
                                    <p>
                                        If you choose to remove or disable these targeting or advertising cookies, 
                                        you will still see adverts but they may not be relevant to you.
                                    </p>
                                    
                                    <p>
                                        <input type="checkbox" id="privacy-advertising-consent" name="targeting">
                                        <label for="privacy-advertising-consent" id="targeting_label">Allow</label>
                                    </p>
                                </section>
                                <section class="ps-detailed-disclosure__section">
                                    <h1 class="ps-detailed-disclosure__section__head">More information</h1>
    
                                    <p>To find out more, please visit our <a href="https://eriklebel.net/privacy-policy" target="_blank">Privacy Policy</a>.</p>
                                </section>
                                
                                <section>
                                    <p>
                                        <button class="j-save-accept ps-privacy__button">Accept all</button>
                                        <button class="j-save-decline ps-privacy__button">Decline all</button>
                                        <button class="j-save-privacy-settings ps-privacy__button">Save choices</button>
                                    </p>
                                </section>
                            </section>
                        </form>
                    </div>`
        );
    }

    interface PrivacySettings {
        tracking?: boolean | undefined;
        advertising?: boolean | undefined;
    }

    function savePrivacySettings(settings: PrivacySettings) {
        setCookie(sitePrivacySettingsCookie, JSON.stringify(settings), 750);
        gtag('consent', 'update', {
            'ad_storage': settings.advertising ? 'granted' : 'denied',
            // 'ad_user_data': 'denied',
            // 'ad_personalization': 'denied',
            'analytics_storage': settings.tracking ? 'granted' : 'denied',
        });
    }

    function loadConsentForm(initialSettings: PrivacySettings = {}) {
        const dialog = renderDialog();

        const briefDisclosurePanel = dialog.querySelector(".j-brief-disclosure")!;
        const detailedDisclosurePanel = dialog.querySelector(".j-detailed-disclosure")!;

        const trackingConsentCheckbox = dialog.querySelector("#privacy-tracking-consent") as HTMLInputElement;
        const advertisingConsentCheckbox = dialog.querySelector("#privacy-advertising-consent") as HTMLInputElement;

        const consentToUseButton = dialog.querySelectorAll(".j-save-accept");
        const declineUseButton = dialog.querySelectorAll(".j-save-decline")!;
        const customizePrivacySettingsButton = dialog.querySelector(".j-change-privacy-settings")!;

        const saveSettingsButton = dialog.querySelector(".j-save-privacy-settings")!;

        trackingConsentCheckbox.checked = !!initialSettings.tracking;
        advertisingConsentCheckbox.checked = !!initialSettings.advertising;

        saveSettingsButton.addEventListener('click', (e) => {
            e.preventDefault();
            savePrivacySettings({
                tracking: trackingConsentCheckbox.checked,
                advertising: advertisingConsentCheckbox.checked,
            });
            document.body.removeChild(dialog);
        });

        consentToUseButton.forEach((button) => {
            button.addEventListener('click', (e) => {
                e.preventDefault();
                savePrivacySettings({
                    tracking: true,
                    advertising: true,
                });
                document.body.removeChild(dialog);
            });
        });

        declineUseButton.forEach((button) => {
            button.addEventListener('click', (e) => {
                e.preventDefault();
                savePrivacySettings({
                    tracking: false,
                    advertising: false,
                });
                document.body.removeChild(dialog);
            });
        });

        customizePrivacySettingsButton.addEventListener('click', (e) => {
            e.preventDefault();
            detailedDisclosurePanel.classList.remove('ps-privacy-hidden');
            briefDisclosurePanel.classList.add('ps-privacy-hidden');
        })
        document.body.append(dialog);
    }

    function readSettings(): PrivacySettings | null {
        try {
            const settings = getCookie(sitePrivacySettingsCookie);
            return JSON.parse(settings);
        } catch (e) {
            return null;
        }
    }

    function loadInteractiveView() {
        const settings = readSettings();
        const asked = settings != null;
        if (!asked) {
            loadConsentForm({});
        }

        const openConsent = renderHtml(`<a href="#">Update cookies preferences</a>`);
        openConsent.addEventListener('click', (e) => {
            e.preventDefault();
            const settings = readSettings();
            loadConsentForm(settings || {});
        });
        const connectionHolder = document.querySelector('.j-privacy-settings');
        if (!connectionHolder) {
            document.body.append(openConsent);
        } else {
            connectionHolder.append(openConsent);
        }
    }

    initDefaultTracking();

    document.addEventListener("DOMContentLoaded", function () {
        loadInteractiveView();
    });

}

privacyCheck();
